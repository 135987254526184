<template>
  <div class="rounded shadow-lg bg-white">
    <form class="p-6 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">
              Team Information
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed privately.
            </p>
          </div>

          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Team Name</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <input
                    placeholder="Team Name"
                    v-model="teamName"
                    type="text"
                    name="team-name"
                    id="team-name"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500"
                    :class="[
                      teamNameRequired ? 'border-red-500' : '',
                      user.role !== 'owner' ? 'cursor-not-allowed' : '',
                    ]"
                    :readonly="user.role !== 'owner'"
                  />
                  <div
                    v-if="teamNameRequired"
                    class="text-red-500 text-xs italic pt-2"
                  >
                    Team Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="user.role === 'owner' && user.team.apiKey"
            class="space-y-6 sm:space-y-5"
          >
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >API Key</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md relative">
                  <input
                    :disabled="true"
                    :value="user.team.apiKey"
                    type="text"
                    name="api-key"
                    id="api-key"
                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500"
                    :readonly="true"
                  />
                  <ArrowPathIcon
                    @click="refreshApiKey"
                    class="absolute right-1 top-1 w-8 opacity-80 active:text-indigo-600 hover:text-indigo-400 cursor-pointer text-indigo-700"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="user.team.autoJoinPossible" class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Auto Join</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <SwitchGroup as="div" class="flex items-center">
                    <SwitchVue
                      :disabled="user.role !== 'owner'"
                      v-model="autoJoin"
                      :class="[
                        autoJoin ? 'bg-indigo-600' : 'bg-gray-200',
                        user.role !== 'owner'
                          ? 'cursor-not-allowed opacity-50'
                          : '',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          autoJoin ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        ]"
                      />
                    </SwitchVue>
                    <SwitchLabel as="span" class="ml-3 text-sm">
                      <span class="font-medium text-gray-900"
                        >Add anyone with
                        <strong>@{{ user.profile.email.split("@")[1] }}</strong>
                        email to this team</span
                      >
                    </SwitchLabel>
                  </SwitchGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      class="bg-gray-50 rounded-b px-4 py-3 text-right sm:px-6"
      v-if="user.role === 'owner'"
    >
      <button
        :disabled="user.role !== 'owner'"
        @click.prevent="teamProceed()"
        type="submit"
        class="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      >
        Update
      </button>
    </div>
  </div>
  <div class="rounded shadow-lg bg-white mt-6">
    <form class="p-6 space-y-8 divide-y divide-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">
              Add New Members
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Invite your team to share clips.
            </p>
          </div>

          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Invite Link</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <div class="flex mt-1">
                    <input
                      readonly
                      type="text"
                      name="invite-link"
                      id="invite-link"
                      :value="inviteLink"
                      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500 mr-4"
                    />
                    <button
                      @click="copyInviteLink"
                      type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="username"
                class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >Email Invite</label
              >
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="rounded-md">
                  <div class="flex mt-2">
                    <input
                      placeholder="Enter the email address"
                      required
                      v-model="inviteEmail"
                      type="email"
                      name="invite-email"
                      id="invite-email"
                      :class="{ 'border-red-500': inviteEmailError }"
                      class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 text-gray-500 mr-4"
                    />
                    <button
                      @click="inviteMember(inviteEmail)"
                      type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Invite
                    </button>
                  </div>
                  <p
                    v-if="inviteEmailError"
                    class="text-red-500 text-xs italic pt-2"
                  >
                    {{ inviteEmailError }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <form>
    <div class="px-4 sm:px-6 lg:px-8 rounded shadow-lg bg-white p-6 mt-6">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            Team Members
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            All members can view all projects and clips.
          </p>
        </div>
      </div>
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Status
            </th>
            <th
              scope="col"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Role
            </th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <TeamItem
            v-for="member in user.team.members"
            :key="member.id"
            :user="member"
            :delete-enabled="user.role === 'owner'"
            @delete-member="deleteTeamMember"
          />
          <TeamItem
            v-for="email in user.team.pendingInvites"
            :key="email"
            :user="email"
            :delete-enabled="user.role === 'owner'"
            @delete-member="deleteTeamMember"
            @resend-invite="inviteMember(email, true)"
          />
        </tbody>
      </table>
    </div>
    <div class="mt-1"></div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { datadogLogs as log } from "@datadog/browser-logs";
import { Switch as SwitchVue, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ArrowPathIcon } from "@heroicons/vue/20/solid";
import TeamItem from "../components/Home/TeamItem.vue";
import EnsureCloud from "../helpers/ensure-cloud";

export default defineComponent({
  components: { TeamItem, SwitchVue, SwitchGroup, SwitchLabel, ArrowPathIcon },
  data() {
    return {
      teamName: "",
      inviteLink: "",
      inviteEmail: "",
      teamNameRequired: false,
      inviteEmailError: "",
      autoJoin: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    async autoJoin(newValue) {
      const updatedUser = await Cloud.teamAutoJoin({ autoJoin: newValue })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        this.$store.commit("setUser", updatedUser);
      }
    },
  },
  mounted() {
    EnsureCloud();
  },
  created() {
    if (this.user) {
      this.teamName = this.user.team.name;
      this.autoJoin = this.user.team.autoJoin;
      this.inviteLink = `${
        import.meta.env.VITE_APP_ENDPOINT || "https://app.dashcam.io"
      }/invite?code=${this.user.team.invite.code}`;
    }
  },
  methods: {
    ...mapActions(["editTeamName"]),
    async teamProceed() {
      if (!this.teamName) {
        this.teamNameRequired = true;
      } else {
        this.teamNameRequired = false;
        await this.editTeamName(this.teamName);
        this.$toast.info("Team has been saved");
      }
    },
    async refreshApiKey() {
      const apiKey = await Cloud.refreshApiKey()
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });
      if (!apiKey) return;
      const user = await Cloud.whoami()
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });
      if (user) {
        this.$store.commit("setUser", user);
      }
    },
    copyInviteLink() {
      this.$copyText(this.inviteLink).then(
        () => {
          this.$toast.open({
            message: "Copied link to clipboard",
            type: "info",
          });

          log.logger.info("Copied Invite Link", {
            user: this.user,
          });
        },
        (e) => {
          log.logger.error(e);
          this.$toast.open({
            message: "There was a problem copying to clipboard",
            type: "error",
          });
        }
      );
    },
    async deleteTeamMember(user) {
      const deleteFunction = user.id
        ? Cloud.teamMemberDelete({ userId: user.id })
        : Cloud.teamPendingDelete({ email: user });

      const updatedUser = await deleteFunction
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        if (user.id) {
          this.$toast.info(`${user.profile.name} has been removed from team`);
        } else {
          this.$toast.info(`${user} has been removed from the pending invites`);
        }
        this.$store.commit("setUser", updatedUser);
      }
    },
    async inviteMember(email, onlySendEmail = false) {
      if (!email) {
        this.inviteEmailError = "Email is required";
        return;
      }

      if (
        // eslint-disable-next-line no-useless-escape
        !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
          email
        )
      ) {
        this.inviteEmailError = "Email is invalid";
        return;
      }

      this.inviteEmailError = "";
      const updatedUser = await Cloud.teamInviteMember({
        email,
        onlySendEmail,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      this.$toast.success(`User ${email} was invited`);

      if (!onlySendEmail && updatedUser) {
        this.$store.commit("setUser", updatedUser);
        this.inviteEmail = "";
      }
    },
  },
});
</script>
